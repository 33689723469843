
import RcDomainMap from "../../../common/RcDomainMap.js";
import Result from './Result.js';
import StringUtils from "@/utils/StringUtils.js";

export default class ResultMap extends RcDomainMap {
  static MODEL_NAME = Result.MODEL_NAME  + "Map";
  
  constructor(domain, data) {
    super(domain, data, ResultMap.MODEL_NAME);
  }

  copy() {
    var data = this._copy();
    return new ResultMap(this.domain, data);
  }
  
  find() {
    var keys = this.keys();
    var found = new ResultMap(this.domain, {} );
    for (var index = 0; index < keys.length; index++) {
      var key = keys[index];
      var data = this.domain.results().findById(key);
      var item = new Result(this.domain, data);
      if (!item.isNew()) {
        found.add(item);
      }
    }
    
    return found;
  }
  
  list() {
    var values = [];
    var keys = this.keys();
    for (var index = 0; index < keys.length; index++) {
      var key = keys[index];
      var item = this.getResult(key);
      values.push(item);
    }
    return values;
  }
  
  getResult(key) {
    var value = super.get(key);
    return new Result(this.domain, value);
  }
  
  addResult(result) {
    super.add(result);
    return this;
  }
  
  addResults(results) {
    super.addAll(results);
    return this;
  }
  
  vehicle() {
    var found = new ResultMap(this.domain, {} );
    var keys = this.keys();
    for (var index = 0; index < keys.length; index++) {
      var key = keys[index];
      var item = this.getResult(key);
      if (item.vehicle().find().isVehicle()) {
        found.add(item);
      }
    }
    return found;
  }
  
  trailers() {
    var found = new ResultMap(this.domain, {} );
    var keys = this.keys();
    for (var index = 0; index < keys.length; index++) {
      var key = keys[index];
      var item = this.getResult(key);
      if (item.vehicle().find().isTrailer()) {
        found.add(item);
      }
    }
    return found;
  }
  
  forVehicle(vehicle) {
    var found = new ResultMap(this.domain, {} );
    var keys = this.keys();
    for (var index = 0; index < keys.length; index++) {
      var key = keys[index];
      var item = this.getResult(key);
      if (item.vehicle().id() === vehicle.id()) {
        found.add(item);
      }
    }
    return found;
  }
  
  initial() {
    var keys = this.keys();
    for (var index = 0; index < keys.length; index++) {
      var key = keys[index];
      var item = this.getResult(key);
      if (item.isInitial()) {
        return item;
      }
    }
    return new Result(this.domain, {});
  }
  
  initials() {
    var keys = this.keys();
    var map = new ResultMap(this.domain);
    for (var index = 0; index < keys.length; index++) {
      var key = keys[index];
      var item = this.getResult(key);
      if (item.isInitial()) {
        map.add(item);
      }
    }
    return map;
  }
  
  enroute() {
    var keys = this.keys();
    for (var index = 0; index < keys.length; index++) {
      var key = keys[index];
      var item = this.getResult(key);
      if (item.isEnroute()) {
        return item;
      }
    }
    return new Result(this.domain, {});
  }
  
  enroutes() {
    var map = new ResultMap(this.domain);
    var keys = this.keys();
    for (var index = 0; index < keys.length; index++) {
      var key = keys[index];
      var item = this.getResult(key);
      if (!item.isInitial()) {
        map.add(item);
      }
    }
    return map;
  }
  
  mostSevereUnfixedDefectType() {
    var keys = this.keys();
    var mostSevere = Result.Values.DefectType.DEFECT_TYPE_NONE;
    for (var index = 0; index < keys.length; index++) {
      var key = keys[index];
      var item = this.getResult(key);
      if (item.isDetached()) {
        continue;
      }
      var defectType = item.mostSevereUnfixedDefectType();
      if (StringUtils.equals(defectType, Result.Values.DefectType.DEFECT_TYPE_MAJOR)) {
        mostSevere = defectType;
        break;
      } else if (StringUtils.equals(defectType, Result.Values.DefectType.DEFECT_TYPE_MINOR)) {
        if (StringUtils.equals(mostSevere, Result.Values.DefectType.DEFECT_TYPE_MAJOR)) {
          continue;
        }
        mostSevere = defectType;
      } else {
        if (StringUtils.equals(mostSevere, Result.Values.DefectType.DEFECT_TYPE_MAJOR)) {
          continue;
        }
        if (StringUtils.equals(mostSevere, Result.Values.DefectType.DEFECT_TYPE_MINOR)) {
          continue;
        }
        mostSevere = defectType;
      }
    }
    
    return mostSevere;
  }
}