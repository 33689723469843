

export default class InspectionUtils {
  
  static SortByCreatedDate = function(a, b) {
    if (!a && !b) {
      return 0;
    }
    if (a.createdDate().time() < b.createdDate().time()) {
      return -1;
    }
    if (a.createdDate().time() > b.createdDate().time()) {
      return 1;
    }
    return 0;
  }
  
}